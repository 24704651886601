.is-contain img {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
    scale: 100%;
}

img.footer-social-icon{
        max-height: 2rem !important;
}

.contact-form{
        width: 100%;
        height: 100%;
        min-height: 650px;
}

div[itemtype="https://schema.org/Question"] {
        margin-top: 2rem;        
}

.batch-made-in-canada {
        position: fixed;
        z-index: 1;
        bottom: 8px;
        right: 8px;
}

.batch-made-in-canada img {
        width: 15vw;
        height: auto;
        min-width: 96px;
        min-height: 96px;
}

@media (min-width: 769px) {
        .batch-made-in-canada {
                position: fixed;
                z-index: 1;
                top: 64px;
                right: 8px;
        }
        
        .batch-made-in-canada img {
                width: 128px;
                height: 128px;
        } 
}

.navbar img {
    max-height: 3rem !important;
}

.navbar .navbar-item:has(img) {
    padding-top: 0;
    padding-bottom: 0;
}

.background-image-home {
        background-image: url(../assets/AdobeStock_268223110.jpeg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
}


// .is-hidden-signed-in, .is-hidden-signed-out {
    //     display: none !important;
    // }
    
    body:not(.user-signed-in):not(.user-signed-out) .is-hidden-signed-in, body:not(.user-signed-in):not(.user-signed-out) .is-hidden-signed-out {
        display: none !important;
    }

body.user-signed-in .is-hidden-signed-in {
    display: none !important;
}

body.user-signed-out .is-hidden-signed-out {
    display: none !important;
}
// body.user-signed-in .is-hidden-signed-out {
//     display: inline-block !important;
// }

// body.user-signed-out .is-hidden-signed-in {
//     display: inline-block !important;
// }

// img {
//     background-image:url('../assets/logo-nfe-192.svg');
//     background-repeat: no-repeat;
//     background-size: cover;
// }
.card-image img, figure img{
    // background-image:url('../assets/logo-nfe-512.svg');
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
}


// .navbar-item svg {
//     width: auto;
//     max-height: 2rem;
// }

.product-details-1 {
    
}

.product-details-2 { 
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2px 2px;
    grid-auto-flow: row;
  }  

.is-fullwidth {
    width: 100%;
}

.icon-button {
    font-size: 1.5rem;
    cursor: pointer;
    // align-self: center;
    padding: 0.5rem 0.75rem;
}

.is-max-width-fit-content{
    max-width: fit-content;
}

.carousel img{
    cursor: zoom-in;
}

.carousel {
    overflow-x: hidden;
}

.column .card {
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content:space-between;
}

.column .card-content {
    height: 100%;
}

.column .card-footer {
    // justify-self: flex-end;
}

.icon-text img{
    max-height: 1.25em;
    position: relative;
    left: 0;
    top: 0.15em;
}

.has-animation-fade-in {

}

.animation-fade-in { 
    animation: fadeIn 1s;
 }

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }


@media print {
    .is-hidden-print{
        display: none !important;
    }
}

.is-same-height .column{
    height: 100%;
}